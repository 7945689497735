<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('requests.active.detail.title')"
        :sub-title="$t('requests.active.detail.desc')"
    />
    <b-row>
      <b-col sm="8">

        <section
            class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      "
        >
          <div class="package-details-table bg-white rounded-14">
            <h1 class="package-details-table__title text-font-main text-med-14">
              {{ $t('requests.active.detail.account') }}
            </h1>

            <b-row class="package-details-table__body">
              <b-col sm="6" class="package-details-name">
                <p class="mb-0">{{ $t('table.provider_logo') }}</p>
              </b-col>
              <b-col sm="6" class="package-details-value">
                <div class="provider-box">
                  <img :src="providerDetail.image" alt="">

                </div>
              </b-col>
            </b-row>


            <b-row class="package-details-table__body">
              <b-col sm="6" class="package-details-name">
                <p class="mb-0">{{ $t('personal_information.service_provider_name') }}</p>
              </b-col>
              <b-col sm="6" class="package-details-value">
                <p class="mb-0">{{ providerDetail.name }}</p>
              </b-col>
            </b-row>


            <b-row class="package-details-table__body">
              <b-col sm="6" class="package-details-name">
                <p class="mb-0">{{ $t('table.applied_at') }}</p>
              </b-col>
              <b-col sm="6" class="package-details-value">
                <p class="mb-0">{{ moment(providerDetail.created_at).format('ll') }}</p>
              </b-col>
            </b-row>


            <b-row class="package-details-table__body">
              <b-col sm="6" class="package-details-name">
                <p class="mb-0">{{ $t('personal_information.service_provider_type') }}</p>
              </b-col>
              <b-col sm="6" class="package-details-value">
                <p class="mb-0">{{ providerDetail.account_type }}</p>
              </b-col>
            </b-row>


            <b-row class="package-details-table__body">
              <b-col sm="6" class="package-details-name">
                <p class="mb-0">{{ $t('common.city') }}</p>
              </b-col>
              <b-col sm="6" class="package-details-value">
                <p class="mb-0">{{ providerDetail.location }}</p>
              </b-col>
            </b-row>

            <b-row class="package-details-table__body">
              <b-col sm="6" class="package-details-name">
                <p class="mb-0">{{ $t('common.email') }}</p>
              </b-col>
              <b-col sm="6" class="package-details-value">
                <p class="mb-0">{{ providerDetail.email }}</p>
              </b-col>
            </b-row>


            <b-row class="package-details-table__body">
              <b-col sm="6" class="package-details-name">
                <p class="mb-0">{{ $t('personal_information.personal_information') }}</p>
              </b-col>
              <b-col sm="6" class="package-details-value">
                <p class="mb-0">{{ providerDetail.about }}</p>
              </b-col>
            </b-row>


            <b-row class="package-details-table__body">
              <b-col sm="6" class="package-details-name">
                <p class="mb-0">{{ $t('table.provider_files') }}</p>
              </b-col>
              <b-col sm="6" class="package-details-value provider-media">
                <a :href="file.doc" target="_blank" class="mb-0 provider-media-file" v-for="file in providerDetail.files">
                  <span class="overlay"></span>
                  <span class="text_pdf">PDF</span>
                  <folder-icon class="icon"/>
                </a>
              </b-col>
            </b-row>


          </div>
        </section>


        <b-card-footer class="px-4 py-3 bg-white">
          <div class="d-flex flex-wrap justify-content-between">
            <div class="px-4 py-3">
              <wameed-btn
                  classes="  text-med-14 text-white rounded-10"
                  :title="$t('common.approve_account')"
                  :disabled="invalid"
                  variant="main"
                  @submitAction="approve"
              />
            </div>
            <div class="px-4 py-3">
              <wameed-btn
                  classes="  text-med-14 text-font-secondary rounded-10 "
                  :title="$t('common.reject')"
                  type="button"
                  variant="gray"
                  @submitAction="reject"
              />
            </div>
          </div>
        </b-card-footer>
      </b-col>
    </b-row>
    <provider-reject-modal
        :visible="showModal"
        :provider-id="providerDetail.id"
        @close="showModal = false"
        @onComplete="()=>loadData()"
    ></provider-reject-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

import PageHeader from '@/components/PageHeader.vue';
import WameedBtn from "@/components/WameedBtn";
import ProviderRejectModal from "@/views/pages/requests/providers/components/ProviderRejectModal";

export default {
  components: {
    ProviderRejectModal,
    WameedBtn,

    PageHeader,

  },
  data() {
    return {

      showModal: false,
    };
  },
  computed: {
    ...mapGetters({
      providerDetail: 'getRequestDetails',
    }),

  },

  async created() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      loadProviderRequestDetail: 'loadProviderRequestDetail',
      approveProvider: "approveProviderRequest"
    }),

    loadData() {
      this.loadProviderRequestDetail(this.$router.currentRoute.params.id);
    },

    reject() {
      this.showModal = true;
    },

    approve() {
      this.approveProvider(this.$router.currentRoute.params.id);

    }

  },
};
</script>
